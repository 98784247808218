<template>
<section>
    <img src="@/assets/img/bkg-1.png" class="deco one" aria-hidden="true"/> 
     <img src="@/assets/img/bkg-2.png" class="deco two" aria-hidden="true"/> 
    <div class="wrapper">
        <h2>El mejor momento para hacer un Design Sprint es cuando...</h2>
        <div class="grid">
            <div class="wow fadeIn">
                <h3>Estás por crear un nuevo producto</h3>
                <p>No arriesgues un centavo en una solución que tus clientes no amen.</p>
            </div>
            <div class="wow fadeIn" data-wow-delay=".2s">
                <h3>Buscás mejorar un producto existente</h3>
                <p>Encontrá las oportunidades de diferenciar la experiencia de tu negocio.</p>
            </div>
            <div class="wow fadeIn" data-wow-delay=".4s">
                <h3>Tenés que resolver un gran desafío</h3>
                <p>Alineá a tu equipo a los verdaderos objetivos del negocio.</p>
            </div>
        </div>
        <img src="@/assets/img/img-grande.png" class="wow slideInUp" alt="fotos del equipo junto con referentes del Design Sprint en el mundo"/>
        <div class="week">
            <h3>Estás a una semana de convertir tu idea en un producto digital</h3>
            <p>¿Todavía no sabes si el Design Sprint te puede ayudar?</p>
            <router-link to="/contacto" class="blue-button">Contactate y averigualo</router-link>
        </div>
    </div>
</section>
</template>

<style scoped>
    section {
        padding: 0 40px;
        margin-top: 70px;
        position: relative
    }
    h2 {
        text-align: center;
        font-weight: bold;
        font-size: 26px;
        line-height: 35px;
        margin-bottom: 48px;
    }
    h3 {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 16px;
    }
    .grid div p {
        margin-bottom: 32px;
        font-family: 'Lato'
    }
   img {
        width: 100%;
    }
    .week h3 {
        font-weight: 800;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 12px;
    }
    .blue-button {
        font-weight: 700;
        background: #2D4AD1;
        color: white;
        padding: 13px 25px;
        border-radius: 3px;
    }
    .blue-button:hover {
        background: #292B2F;
        transition: .5s;
    }
    .week p {
        margin-bottom: 40px;
        font-family: 'Lato';
    }
    .deco {
        display: none;
    }
    @media all and (min-width: 768px) {
        section {
            position: relative;
            margin-top: 120px;
            margin-bottom: 0px;
        }

        .grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 30px;
        }
        .week {
            transform: translateY(-100%);
        }
    }
    @media all and (min-width: 1200px) {
        .wrapper {
            padding: 0 120px;
        }
        h2 {
            font-size: 26px;
            line-height: 36px;
            width: 60%;
            margin: 0 auto 48px auto;
        }
        h3 {
            font-size: 20px;
            line-height: 27px;
        }
        .grid div p {
            font-size: 18px;
            line-height: 30px;
        }
        .week h3{
            font-size: 26px;
            line-height: 36px;
        }
        .deco {
            display: block;
            width: 250px;
            position: absolute;
        }
        .one {
            left: 0;
            top: -150px;
        }
        .two {
            right: 0;
            top: -120px;
        }
    }
    @media all and (min-width: 1600px) {
        .week {
            transform: translateY(-150%);
        }
        .wrapper {
            max-width: 1300px;
            margin: 120px auto 0px auto;
            padding: 0;
        }
    }
</style>