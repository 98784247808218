<template>
    <section class="wrapped">
        <div class="wow slideInLeft column-one">
            <img src="@/assets/img/img2-home.png"/>
        </div>
        <div class="wow slideInLeft column-two">
            <p>Si crees que tu idea o producto digital hace del mundo un lugar mejor, podemos ayudarte a crecer.</p>
            <router-link to="/contacto" class="blue-button">Contactate con nuestro equipo</router-link>
        </div>
    </section>
</template>
<script>
export default {
    props: ['isDesignSprint']
}
</script>
<style scoped>
section {
    padding: 0 40px;
}
    img {
        width: 100%;
        margin-bottom: 48px;
    }
    p {
        font-size: 22px;
        font-weight: 800;
        margin-bottom: 32px;
    }
    .blue-button {
        background: #2D4AD1;
        padding: 13px 34px;
        border-radius: 3px;
        color: white;
        font-weight: 700;
        display: block;
        text-align: center;
    }
    .blue-button:hover {
        background: #292B2F;
        transition: .5s;
    }

    @media all and (min-width: 768px) {
        section {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
            grid-gap: 60px;
            padding: 0 120px;
            align-items: center;
        }

        p {
            font-size: 26px;
            line-height: 36px;
        }
        .blue-button {
            display: inline;
        }
    }
    @media all and (min-width: 1200px) {
        .wrapped {
           padding: 0 120px;
        }
    }
    @media all and (min-width: 1600px) {
        .wrapped {
            padding: 0px;
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>