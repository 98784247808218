<template>
    <section class="benefits">
        <div class="wrapper">
        <h2 class="wow fadeIn">
            <span>No solo son beneficios,</span>
            <span class="bolder">son un compromiso con nuestro equipo</span>
        </h2>
        <div class="grid">
            <div class="wow fadeIn">
                <h3>Tu bienestar es fundamental</h3>
                <p>Vamos más allá de la licencia tradicional. Nos importa que descanses y que tengas tiempo para vos.</p>
            </div>
            <hr class="mobile"/>
            <div class="wow fadeIn" data-wow-delay=".2s">
                <h3>Nos importa tu crecimiento</h3>
                <p>Queremos verte crecer. Nuestro equipo cuenta con capacitaciones pagas y sesiones de coaching.</p>
            </div>
            <hr class="mobile"/>
            <div class="wow fadeIn" data-wow-delay=".4s">
                <h3>Modalidad 100% distribuída</h3>
                <p>Elegí el paraíso que más te guste y trabaja desde ese lugar. La oficina la elegís vos.</p>
            </div>
            <hr class="mobile"/>
            <div class="wow fadeIn" data-wow-delay=".6s">
                <h3>Nos orientamos a resultados</h3>
                <p>Aunque contamos con un horario que nos permite organizarnos, lo más importante son los objetivos.</p>
            </div>
            <hr class="mobile"/>
            <div class="wow fadeIn" data-wow-delay=".8s">
                <h3>Trabajamos en equipo</h3>
                <p>Buscamos ganar el campeonato. Dentro de NNIDO el trabajo es colaborativo, en equipo y comprometido.</p>
            </div>
            <hr class="mobile"/>
            <div class="wow fadeIn" data-wow-delay="1s">
                <h3>Cada vez más transparentes</h3>
                <p>Buscamos que la información esté cada vez más accesible para las distintas personas del equipo.</p>
            </div>
        </div>
        <img class="three-photos wow slideInUp" src="@/assets/img/three_photos.png" />
        <p class="quote">Nuestro equipo sigue creciendo. Conocé las oportunidades abiertas y nuestro proceso de selección.
        <a href="#open-positions">Postulate<img src="@/assets/img/blue-arrow-down.svg"/></a>
        </p>
        </div>
    </section>
</template>
<style scoped>
.benefits {
    background: #F6F4F3;
    padding: 50px 40px;
}
h2 span {
    display: block;
    font-weight: 700;
    color: #2D4AD1;
}
.bolder {
    font-weight: 800;
}
.grid div {
    margin: 32px 0px;
}
h3 {
    margin-bottom: 14px;
}
.three-photos {
    width: 100%;
    margin-bottom: 32px;
}
p {
    font-size: 14px;
    font-family: 'Lato';
    line-height: 22px;
}
.quote {
    font-size: 16px;
    line-height: 24px;
}
a {
    display: block;
    font-weight: 600;
    color: #2D4AD1;
    margin-top: 32px;
}
a img {
    margin-left: 8px;
}
@media all and (min-width: 768px) {

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 16px;
        margin-top: 70px;
    }
    .grid div {
        margin: 0;
    }
    h2 span {
        text-align: center;
    }
    .mobile {
        display: none;
    }
    .quote {
        width: 50%;
        position: relative;
        top: -100px;
    }
    h2 {
        font-size: 42px;
    }
    .three-photos {
        margin-top: 40px;
    }
}
@media all and (min-width: 1200px) {
    .benefits {
        padding: 50px 120px;
    }
    .grid {
        grid-gap: 60px;
    }
    h3 {
        font-size: 20px;
    }
    p {
        font-size: 18px;
        line-height: 30px;
    }
    .quote {
        top: -160px;
        font-size: 20px;
    }
}
@media all and (min-width: 1600px) {
    .wrapper {
        padding: 50px 0px;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
    .quote {
        top: -200px;
    }
}
</style>