<template>
<div class="experience">
  <NavbarBlack/>
  <Header/>
  <CasesComponent/>
  <ListenComponent/>
  <LearningComponent/>
  <GrowingComponent/>
</div>
</template>
<script>
import NavbarBlack from '@/components/NavbarBlack'
import Header from '@/components/Experience/Header'
import CasesComponent from '@/components/Experience/CasesComponent'
import ListenComponent from '@/components/Experience/ListenComponent'
import LearningComponent from '@/components/Experience/LearningComponent'
import GrowingComponent from '@/components/Experience/GrowingComponent'
export default {
    components: {Header, CasesComponent, ListenComponent, LearningComponent, GrowingComponent, NavbarBlack}
}
</script>
<style scoped>

</style>