<template>
    <footer>
      <!--<div class="footer-column">
        <h2>Somos NNIDO</h2>
        <a :href="`${baseUrl}/cultura`">Cultura</a>
      </div>
      <div class="footer-column">
        <h2>Aprendé</h2>
        <a :href="`${baseUrl}/experiencia`">Experiencia</a>
      </div>
      <div class="footer-column">
        <h2>Design Sprint</h2>
        <a :href="`${baseUrl}/design-sprint`">¿Cómo funciona?</a>
      </div>-->
      <div class="footer-column">
        <h2>Sumate a nuestra comunidad</h2>
        <div class="social-icons">
          <a href="https://www.instagram.com/aprendeconnnido/" target="_blank"><img src="@/assets/img/ico_instagram.svg" alt="Instagram icon"></a>
          <a href="https://ar.linkedin.com/company/nnido" target="_blank"><img src="@/assets/img/ico_linkedin.svg" alt="LinkedIn icon"></a>
          <a href="https://medium.com/nnido" target="_blank"><img src="@/assets/img/ico_medium.svg" alt="Medium icon"></a>
        </div>
      </div>
      <div class="footer-column">
        <a><img src="@/assets/img/logo_nnido_white.svg" alt="NNIDO"></a>
      </div>
    </footer>
</template>
<script>
export default {
  data: function () {
    return {     
      baseUrl: 'http://nnido.com.ar'
    }
  }
}
</script>
<style scoped>
footer {
    background: #2D4AD1;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 37px 105px;
}
footer a {
    color: white;
    display: block;
    font-family: "Work Sans", sans-serif;
    font-size: 18px;
    margin-bottom: 8px;
}
footer h2 {
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
    margin-bottom: 12px;
    color: white;
}
.social-icons {
    display: flex;
}

.social-icons a {
    margin-right: 8px;
    margin-bottom: 0px;
}
@media all and (max-width: 980px) {
    footer {
        flex-direction: column;
        padding-left: 200px;
    }
    .footer-column {
        margin-bottom: 12px;
    }
}
@media all and (max-width: 768px) {
  footer {
    padding: 30px;
  }
}
</style>