<template>
    <section id="culture" class="incredible-section">
        <div class="column-one wow fadeIn">
            <h2>Hacemos cosas increíbles con personas increíbles</h2>
            <p>Buscamos personas curiosas, creativas y que busquen mejorar día a día. Colaboramos en equipo para construir una cultura positiva, diversa y solidaria.</p>
            <a href="#testimonies">Nuestro equipo dice...</a>
        </div>
        <div class="column-two wow fadeIn" data-wow-delay=".2s">
            <img src="@/assets/img/culture-image-incredible.png" class="main-image"/>
        </div>
    </section>
</template>
<style scoped>
section {
    margin: 0 40px 100px 40px;
}
.main-image {
    width: 100%;
}
h2 {
    font-size: 26px;
    margin-bottom: 28px;
}
p {
    font-size: 16px;
    margin-bottom: 32px;
    font-family: 'Lato';
    line-height: 24px;
}
a {
    font-weight: 700;
    color: #2D4AD1;
}
.column-two {
    margin-top: 32px;
}
@media all and (min-width: 768px) {
    section {
        display: grid;
        grid-template-columns: 60% 40%;
        align-items: center;
    }
    .column-one {
        margin-right: 16px;
    }
}
@media all and (min-width: 980px) {
    section {
        grid-template-columns: 50% 50%;
        grid-gap: 40px;
    }
    .main-image {
        max-width: 70%;
    }
}
@media all and (min-width: 1200px) {
    section {
        margin: 0px 120px 100px 120px;
        grid-gap: 120px;
    }
    h2 {
        font-size: 42px;
    }
    p {
        font-size: 22px;
        line-height: 34px;
    }
    a {
        font-size: 18px;
    }
}
@media all and (min-width: 1200px) {
    section {
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>