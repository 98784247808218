<template>
    <section class="design-sprint" :style="margin ? {'margin-top': '0px'} : null">
        <h2>Tenemos experiencia ayudando grandes y pequeños equipos:</h2> 
        <div class="grid">
            <img class="wow fadeIn" src="@/assets/img/brand_1.png" alt="Quilmes"/>
            <img class="wow fadeIn" data-wow-delay=".2s" src="@/assets/img/brand_2.png" alt="Patagonia"/>
            <img class="wow fadeIn" data-wow-delay=".4s" src="@/assets/img/brand_3.png" alt="Exeq"/>
            <img class="wow fadeIn" data-wow-delay=".6s" src="@/assets/img/brand_4.png" alt="June.ai"/>
        </div>
    </section>
</template>
<script>
export default {
props: ['margin']
}
</script>
<style scoped>
    section {
        padding: 0 40px;
    }
    h2, a {
        font-size: 22px;
    }
    a {
        color: #2D4AD1;
    }
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        margin-top: 40px;
    }
    img {
        width: 100%;
    }
    @media all and (min-width: 768px) {
        section {
            padding: 0 120px;
        }
        .grid {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        h2, a {
            font-size: 26px;
            line-height: 36px;
            width: 50%;
        }
    }
    @media all and (min-width: 1600px) {
        .design-sprint {
            max-width: 1300px;
            margin-left: auto; 
            margin-right: auto; 
            padding: 0px;
        }
    }
</style>