import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyB9Em3GrcBA3D1kYbCvbGKtZYiQsuQMJRs",
    authDomain: "nnido-landing.firebaseapp.com",
    databaseURL: "https://nnido-landing.firebaseio.com",
    projectId: "nnido-landing",
    storageBucket: "nnido-landing.appspot.com",
    messagingSenderId: "790753978163",
    appId: "1:790753978163:web:9a18676d449ddf3581c046"
};

Firebase.initializeApp(firebaseConfig);

const app = createApp(App);
//if (ROUTER_ENABLE) {
    app.use(router);
// }
app.mount('#app');


