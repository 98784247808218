<template>
    <section class="wow slideInUp">
        <h2>Bootcamps y workshops</h2>
        <div>
            <h3>Nos inspira el compartir el conocimiento</h3>
            <p>De nuestro amor por el aprendizaje y el compartir nace NNIDO University, una experiencia pensada por diseñadores para diseñadores.</p>
            <!--<a href="#">Conocé más acá<img src="@/assets/img/arrow-right-white.svg"/></a>-->
        </div>
    </section>
</template>
<style scoped>
    section {
        margin: 70px 40px;
        background-color: #2D4AD1;
        background-image: url('../../assets/img/misc-home-1.svg'), url('../../assets/img/misc-home-2.svg'), url('../../assets/img/misc-home-3.svg');
        background-position: 100% 0%, 0% 100%, 100% 100%;
        background-repeat: no-repeat;
        /*height: 70vh;*/
        padding: 60px 20px;
        border-radius: 8px;
    }
    h2, h3, p, a {
        color: white;
    }
    h2 {
        margin-bottom: 40px;
    }
    h3, p {
        margin-bottom: 16px;
    }
    a {
        font-weight: 800;
    }
    a img {
        margin-left: 8px;
    }
    p {
        font-family: 'Lato';
        line-height: 24px;
    }
    @media all and (min-width: 768px) {
        section {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        h2 {
            font-size: 26px;
        }
        p, h2 {
            margin-bottom: 0px;
        }
    }
    @media all and (min-width: 1200px) {
        section {
            padding: 40px 90px;
            margin: 0px 120px 80px 120px;
        }
        p, a {
            font-size: 18px;
            line-height: 30px;
        }
        h3 {
            font-size: 20px;
        }
    }
    @media all and (min-width: 1600px) {
        section {
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>