<template>
<section>
    <div class="black-box">
        <div class="info">
            <h2>Escuchá a nuestro equipo</h2>
            <p class="yellow">“Uno de los aspectos muy positivos del Design Sprint es que es infinitamente modular y siempre se pueden tomar un par de ejercicios.”</p>
            <p>Escuchá “Las maravillas del Design Sprint” por Uxopedia en:</p>
            <div class="platforms">
                <a href="https://open.spotify.com/episode/61MNrkERAN2npMaXH7deWX?si=-4ujuFjERV6bm6q4hT7ecA" target="_blank"><img src="@/assets/img/Spotify-Blanco.svg" alt="logo Spotify"/></a>
                <a href="https://anchor.fm/uxopedia/episodes/Las-maravillas-del-Design-Sprint-eg2fll/a-a2j3tdh " target="_blank"><img src="@/assets/img/Anchor-Blanco.svg" alt="logo Anchor"/></a>
            </div>
        </div>
        <div class="overlayed-image">
            <img src="@/assets/img/img-podcast.png"/>
        </div>
    </div>
    <div class="podcasts">
        <h2>Charlando con la comunidad</h2>
        <div class="podcast-list">
            <div class="podcast">
                <img class="podcast-img" src="@/assets/img/Thumbnail-1.png"/>
                <div>
                    <h3>Diseño para la transformación</h3>
                    <a href="https://www.youtube.com/watch?v=Ng7JUKd5Hj4" target="_blank"><p><span>Mirá en:</span> <img src="@/assets/img/Youtube-Negro.svg"/></p></a>
                </div>
            </div>
            <div class="podcast">
                <img class="podcast-img" src="@/assets/img/Thumbnail-2.png"/>
                <div>
                    <h3>¡Esto pudo ser un Design Sprint!</h3>
                    <a href="https://open.spotify.com/show/2H1QHE2W43P2VtlZ7IK8eZ" target="_blank"><p><span>Escuchá en:</span> <img src="@/assets/img/Spotify-Negro.svg"/></p></a>
                </div>
            </div>
            <div class="podcast">
                <img class="podcast-img" src="@/assets/img/Thumbnail-3.png"/>
                <div>
                    <h3>¡Contactate y hagamos que pase!</h3>
                    <a href="#" class="button">Escribinos</a>
                </div>
            </div>                       
        </div>
    </div>
</section>
</template>

<style scoped>

.black-box {
    min-height: 100vh;
    background: url('../../assets/img/bkg-podcast-mobile.png');
    background-size: cover;
    padding: 40px;
    padding-top: 98px;
}
.black-box h2, .black-box p {
    color: white;
}
h2 {
    margin-bottom: 24px;
}
.black-box .yellow {
    color: #FCD065;
    font-family: "Overpass Mono";
    margin-bottom: 40px;
}
.platforms {
    margin-top: 24px;
}
.platforms a {
    margin-right: 16px;
}
.overlayed-image img {
    width: 100%;
    margin-top: 40px;
}
.podcasts {
    padding: 0 40px;
    margin-top: 64px;
}
.podcast {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    align-items: center;
    margin-bottom: 24px;
}
.podcast-img {
    width: 100%;
}
.podcast h3 {
    margin-bottom: 16px;
}
.button {
    background: #2D4AD1;
    color: white;
    padding: 8px 24px;
    margin-top: 16px;
    display: block;
    border-radius: 3px;
    font-weight: 600;
}
.podcast p {
    display: flex;
    align-items: center;
}
@media all and (min-width: 768px) {
    .black-box {
        background: url('../../assets/img/bkg-podcast-desktop.png');
        background-size: cover;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 25px 120px;
        align-items: center;
        min-height: 70vh;
    }
    .overlayed-image img {
        width: 80%
    }
    .podcast-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    p img {
        margin-left: 8px;
    }
}
@media all and (min-width: 1200px) {
    .black-box {
        grid-gap: 150px;
    }
    .black-box p {
        font-size: 18px;
        line-height: 28px;
    }
    h2 {
        font-size: 42px;
    }
    .podcast-list {
        grid-gap: 92px;
    }
    .podcasts {
        padding: 0 120px;
    }
    .button {
        display: inline-block;
    }
}
</style>
