<template>
    <NavbarGrey/>
    <Header/>
    <InnovationComponent/>
    <MVPComponent/>
    <WhatIsComponent/>
    <SimpleAndComplexComponent/>
    <PrototypeComponent/>
    <JakeComponent/>
    <RightMomentComponent/>
    <BrandsComponent :margin="true"/>
    <DesignComponent :isDesignSprint="true"/>
    <CommitmentComponent/>

</template>

<script>
import NavbarGrey from '@/components/NavbarGrey'
import Header from '@/components/DesignSprint/Header'
import InnovationComponent from '@/components/DesignSprint/InnovationComponent'
import MVPComponent from '@/components/DesignSprint/MVPComponent'
import WhatIsComponent from '@/components/DesignSprint/WhatIsComponent'
import CommitmentComponent from '@/components/DesignSprint/CommitmentComponent'
import SimpleAndComplexComponent from '@/components/DesignSprint/SimpleAndComplexComponent'
import PrototypeComponent from '@/components/DesignSprint/PrototypeComponent'
import RightMomentComponent from '@/components/DesignSprint/RightMomentComponent'
import BrandsComponent from '@/components/Home/BrandsComponent'
import DesignComponent from '@/components/DesignSprint/DesignComponent'
import JakeComponent from '@/components/DesignSprint/JakeComponent'
export default {
    components: {NavbarGrey, Header, InnovationComponent, MVPComponent, CommitmentComponent, WhatIsComponent, 
    SimpleAndComplexComponent, PrototypeComponent, RightMomentComponent, BrandsComponent, DesignComponent, JakeComponent}
}
</script>