<template>
<section>
    <div class="title wow slideInUp">
        <h2>¿Qué estás buscando?</h2>
        <p>Ahora que ya nos conocemos un poco más, contanos sobre tu proyecto y la razón que te trajo a contactarte con nuestro equipo.</p>
        <form>                
            <div class="row">
                <div class="field required">
                    <label>Busco...</label>
                    <div class="input-group">
                        <select v-model="lookingForSelected" @change="handleLookingForChange">
                            <option value="Contratar a su equipo para mi proyecto" selected>Contratar a su equipo para mi proyecto</option>
                            <option value="Entrenar al equipo en Design Sprints">Entrenar al equipo en Design Sprints</option>
                            <option value="Crear contenido en conjunto para la comunidad">Crear contenido en conjunto para la comunidad</option>
                        </select>
                        <span>*Requerido</span>
                    </div>
                </div>
                <div class="field required">
                    <label>Para...</label>
                    <div class="input-group">
                        <select v-model="forSelected" @change="handleForChange">
                            <option v-if="lookingForSelected === 'Contratar a su equipo para mi proyecto'" value="Resolver un prototipo para testear una idea" selected>Resolver un prototipo para testear una idea</option>
                            <option v-if="lookingForSelected === 'Contratar a su equipo para mi proyecto'" value="Crear un sitio web de cero">Crear un sitio web de cero</option>
                            <option v-if="lookingForSelected === 'Contratar a su equipo para mi proyecto'" value="Crear una aplicación de cero">Crear una aplicación de cero</option>
                            <option v-if="lookingForSelected === 'Contratar a su equipo para mi proyecto'" value="Rediseñar una solución existente">Rediseñar una solución existente</option>
                            <option v-if="lookingForSelected === 'Entrenar al equipo en Design Sprints'" value="Agilizar los resultados del equipo" selected>Agilizar los resultados del equipo</option>
                            <option v-if="lookingForSelected === 'Entrenar al equipo en Design Sprints'" value="Brindarles herramientas a quienes lideran">Brindarles herramientas a quienes lideran</option>        
                            <option v-if="lookingForSelected === 'Entrenar al equipo en Design Sprints'" value="Formar en UX a nuestro equipo">Formar en UX a nuestro equipo</option>        
                            <option v-if="lookingForSelected === 'Crear contenido en conjunto para la comunidad'" value="Que participen en mi podcast" selected>Que participen en mi podcast</option>        
                            <option v-if="lookingForSelected === 'Crear contenido en conjunto para la comunidad'" value="Formen parte de nuestro evento como speakers">Formen parte de nuestro evento como speakers</option>                  
                            <option v-if="lookingForSelected === 'Crear contenido en conjunto para la comunidad'" value="Colaborar en comunidad (otros)">Colaborar en comunidad (otros)</option>                                   
                        </select>
                        <span>*Requerido</span>
                    </div>
                </div>
            </div>
            <div class="field required">
                <label>Me gustaría que...</label>
                <div class="input-group" :class="{'error': errors.wouldLikeTo}">
                    <textarea v-model="wouldLikeTo" placeholder="Contanos un poco sobre tu expectativa o lo que te gustaría que pase. 350 caractéres máximo." @input="handleWouldLikeTo"></textarea>
                    <span v-if="!errors.wouldLikeTo">*Requerido</span>
                    <span v-else class="errors">Debés completar este campo</span>
                </div>
            </div>
            <div v-if="lookingForSelected === 'Contratar a su equipo para mi proyecto'" class="field required">
                <label>¿Algo más con lo que te podamos ayudar?</label>
                <div class="input-group checkbox">
                        <div>
                            <input type="checkbox" @click="handleIncludesDevelopmentChange"/>
                            <label class="regular">Me gustaría que incluya desarrollo</label>
                    </div>
            </div>
            </div>
            <div class="row">
                <div v-if="lookingForSelected === 'Entrenar al equipo en Design Sprints'" class="field  required">
                    <label>¿Cuántas personas hay en el equipo?</label>
                    <div class="input-group" :class="{error: errors.numberOfPeople}">
                        <input type="number" v-model="numberOfPeople" @input="handleNumberOfPeopleChange" placeholder="Ingresar cantidad de personas"/>
                        <span v-if="!errors.numberOfPeople">*Requerido</span>
                        <span v-else class="error">Debés completar este campo</span>
                    </div>
                </div>
                <div v-if="lookingForSelected === 'Crear contenido en conjunto para la comunidad'" class="field required">
                    <label>¿Dónde?</label>
                    <div class="input-group" :class="{'error': errors.where}">
                        <input type="text" @input="handleWhereChange" v-model="where" placeholder="Ingresar nombre o URL"/>
                        <span v-if="!errors.where">*Requerido</span>
                        <span v-else class="error">Debés ingresar una URL válida</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
</template>
<script>
export default {
    props: ['errors', 'subscriptionSuccess'],  
    watch: {
        subscriptionSuccess: function (value) {
            console.log(value)
            if (value) {
                this.numberOfPeople = ''
                this.where = ''
                this.wouldLikeTo = ''
            }
        },
     }, 
    data: function () {
        return {
            lookingForSelected: 'Contratar a su equipo para mi proyecto',
            forSelected:"Resolver un prototipo para testear una idea",   
            numberOfPeople: '',
            where: '',
            wouldLikeTo: ''
        }
    },
    methods: {
        handleLookingForChange () {
            if (this.lookingForSelected === 'Contratar a su equipo para mi proyecto') {
                this.forSelected = "Resolver un prototipo para testear una idea"
            } else if (this.lookingForSelected === 'Entrenar al equipo en Design Sprints') {
                this.forSelected = "Agilizar los resultados del equipo"
            } else {
                this.forSelected = "Que participen en mi podcast"
            }
            this.$emit('change', {name: 'lookingFor', value: this.lookingForSelected})
            this.$emit('change', {name: 'for', value: this.forSelected})
        },
        handleForChange () {
            this.$emit('change', {name: 'for', value: this.forSelected})
        },
        handleWouldLikeTo (event) {
            this.$emit('change', {name: 'wouldLikeTo', value: event.target.value})
        },
        handleIncludesDevelopmentChange (event) {
            this.$emit('change', {name: 'includesDevelopment', value: event.target.checked})
        },
        handleNumberOfPeopleChange (event) {
            this.$emit('change', {name: 'numberOfPeople', value: event.target.value})
        },
        handleWhereChange (event) {
            this.$emit('change', {name: 'where', value: event.target.value})
        }
    }
}
</script>
<style scoped>
    section {
        padding: 0 40px;
    }
    h2 {
        font-weight: bold;
        font-size: 26px;
        line-height: 35px;
        margin-bottom: 24px;
    }
    form {
        margin-top: 20px;
    }
    .field:not(first-child) {
        margin-top: 32px;
    }
    label {
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 12px;
        display: block;
    }
    select, input, textarea {
        width: 100%;
        height: 40px;
        border: 1px solid gray;
        border-radius: 5px;
        padding-left: 8px;
        font-size: 16px;
        background: white;
        font-family: 'Lato';
    }
    textarea {
        padding-top: 8px;
        height: 180px;
    }
    select {
        padding-right: 8px;
    }
    .input-group {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .input-group span {
        color: #FE6D70;
        font-weight: bold;
        font-size: 14px;
        line-height: 36px;
    }

    .input-group.checkbox div {
        display: flex;
        align-items: center;
    }
    .input-group.checkbox label {
        display: inline;
        margin-bottom: 0;
    }
    .input-group.checkbox input {
        width: 20px;
        margin-bottom: 0;
        margin-right: 8px;
    }
    .error {
        align-items: flex-start;
    }
    .error input, .error textarea {
        border-color: #FE6D70;
    }
    ::placeholder {
        font-size: 16px;
        font-family: 'Lato';
    }
    .regular {
        font-weight: normal;
    }
    p {
        font-family: 'Lato';
    }
    @media all and (min-width: 768px) {
        .row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px;
        }
        .input-group.checkbox div {
            display: flex;
            align-items: center;
        } 
        .input-group.checkbox {
            align-items: center;
            flex-direction: row;
        }
    }
    @media all and (min-width: 1200px) {
        section {
            padding: 0 120px;
            margin-top: 0px;
            margin-bottom: 70px;
        }
        h2 {
            font-size: 42px;
            line-height: 52px;
        }
        p {
            font-size: 18px;
            line-height: 30px;
            width: 40%;
        }
    }
    @media all and (min-width: 1600px) {
        section {
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>