<template>
<div class="culture">
  <NewNavbar/>
  <Header/>
  <IncredibleComponent/>
  <TestimoniesComponent/>
  <BenefitsComponent/>
  <OpenPositionsComponent/>
  <ProcessComponent/>
  <MailComponent/>
</div>
</template>
<script>
import Header from '@/components/Culture/Header'
import IncredibleComponent from '@/components/Culture/IncredibleComponent'
import TestimoniesComponent from '@/components/Culture/TestimoniesComponent'
import BenefitsComponent from '@/components/Culture/BenefitsComponent'
import OpenPositionsComponent from '@/components/Culture/OpenPositionsComponent'
import ProcessComponent from '@/components/Culture/ProcessComponent.vue'
import MailComponent from '@/components/Culture/MailComponent'
import NewNavbar from '@/components/NewNavbar'
export default {
    components: {NewNavbar, Header, IncredibleComponent, TestimoniesComponent, BenefitsComponent, OpenPositionsComponent, ProcessComponent, MailComponent}
}
</script>
<style scoped>
.culture {
  margin: 0 auto;
}
</style>