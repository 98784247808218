<template>
    <section>
        <h2>El Design Sprint hace simple lo complejo:</h2>
        <div class="grid">
            <div>
                <img src="@/assets/img/etapa1.png" alt="very tangled lines" class="wow fadeIn"/>
                <div class="benefit wow fadeIn" data-wow-delay=".1s">
                    <img src="@/assets/img/Beneficio1.png" aria-hidden="true"/>
                    <h3>Alinea al equipo con la visión</h3>
                </div>
                <h4>Se define el desafío más importante</h4>
                <p>Navegamos el espacio del problema, producimos muchas soluciones y elegimos la mejor para testear.</p>
            </div>
            <div>
                <img src="@/assets/img/etapa2.png" alt="partially untangled lines" class="wow fadeIn" data-wow-delay=".3s"/>
                <div class="benefit wow fadeIn" data-wow-delay=".4s">
                    <img src="@/assets/img/Beneficio2.png" aria-hidden="true"/>
                    <h3 class="two-lines">Acelera y potencia resultados</h3>
                </div>
                <h4>Se construye un prototipo digital</h4> 
                <p>Creamos un prototipo interactivo de alta fidelidad que se ve y siente como un producto real.</p>               
            </div>
            <div>
                <img src="@/assets/img/etapa3.png" alt="untangled lines" class="wow fadeIn" data-wow-delay=".6s"/>
                <div class="benefit wow fadeIn" data-wow-delay=".7s">
                    <img src="@/assets/img/Beneficio3.png" aria-hidden="true"/>
                    <h3>Reduce el riesgo en la inversión</h3>
                </div>  
                <h4>Se testea con 5 personas usuarias</h4>
                <p>Obtenemos insights de clientes reales para validar la solución y guiar el desarrollo del producto.</p>              
            </div>
        </div>
    </section>
</template>
<style scoped>
section {
    padding: 0 40px;
}
img {
    width: 100%;
}
.benefit {
    position: relative;
}
h2 {
    margin-bottom: 48px;
}
.benefit h3 {
    color: white;
    position: absolute;
    top: 13%;
    left: 50%;
    transform: translate(-50%);
    font-size: 24px;
    text-align: center;
}

h4 {
    margin-top: 26px;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
}
p {
    font-size: 14px;
    line-height: 22px;
    font-family: 'Lato';
}
@media all and (min-width: 768px) {
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 32px;
    }
}
@media all and (min-width: 1200px) {
    section {
        padding: 0 120px;
    }
    .benefit h3 {
        font-size: 22px;
        line-height: 32px;
    } 
    h4 {
        font-size: 20px;
        line-height: 27px;
    }   
    p {
        font-size: 18px;
        line-height: 30px;
    }
    h2 {
        width: 40%;
        font-size: 26px;
        line-height: 36px;
    }
    .benefit h3 {
        top: 20%;
    }
}
@media all and (min-width: 1200px) {
    section {
        padding: 0 120px;
    }
}
@media all and (min-width: 1600px) {
    section {
        padding: 0;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
    .benefit h3 {
        top: 35%;
    }
    .benefit .two-lines {
        top: 35%;
    }
}
</style>