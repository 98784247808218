<template>
    <header>
        <div>
            <h1>Nuestra experiencia</h1>
            <a href="#cases"><p class="desktop">Lecciones aprendidas del equipo<img src="@/assets/img/arrow_down_white.svg"/></p></a>
        </div>
        <img class="header-image" src="@/assets/img/img-header.png"/>
        <a href="#cases"><p class="mobile">Lecciones aprendidas del equipo<img src="@/assets/img/arrow_down_white.svg"/></p></a>
    </header>
</template>
<style scoped>
header {
    background: #1A1C1C;
    padding: 40px;
}
.header-image {
    width: 100%;
}
h1, p {
    color: white;
}
p {
    text-align: center;
    margin-top: 20px;
    color: white;
}
p img {
    margin-left: 12px;
}
.desktop {
    display: none;
}
a {
    font-weight: 700;
}
@media all and (min-width: 768px) {
    header {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
    .mobile {
        display: none;
    }
    .desktop {
        display: block;
    }
    p {
        text-align: left;
    }
}
@media all and (min-width: 1200px) {
    header {
        padding: 0px 0px 0px 120px;
    }
    h1 {
        font-size: 70px;
    }
    p {
        font-size: 18px;
    }
}
</style>