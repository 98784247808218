<template>
<nav>
  <div class="nav-logo">
    <router-link to="/"><img :src="require(`@/assets/img/${logo}.svg`)" alt="NNIDO University"/></router-link>
  </div>
  <div class="menu mobile">
    <span @click="isActive=!isActive">Menú</span>
  </div>
  <div class="navbar-links" :class="{active: isActive}">
    <router-link to="/" @click="isActive=false">Home</router-link>
    <router-link to="/cultura" :class="isCurrentPath('cultura')" @click="isActive=false">Cultura</router-link>
    <router-link to="/design-sprint" :class="isCurrentPath('design-sprint')">Design Sprint</router-link>
    <router-link to="/contacto" :class="isCurrentPath('contacto')" @click="isActive=false">Contacto</router-link>
  </div>
</nav>
</template>
<script>
export default {

  computed: {
    logo () {
        return 'nnido_logo'
    },

  },
  data: function () {
    return {
      isActive: false,
      baseUrl: 'http://nnido.com.ar'
    }
  },
  methods: {
    isCurrentPath (path) {
      return {'is-active': window.location.pathname.endsWith(path)}
    }
  }
}
</script>
<style scoped>
nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
}
nav a {
    color: black;
    margin-right: 8px;
    position: relative;
    left: 50%;
    transform: translateX(-25%);
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 36px;
}
.nav-logo a {
  position: static;
}

.navbar-links {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  z-index: 1;
}
.navbar-links.active {
  transform: translateX(0)
}
.router-link-active {
  text-decoration: underline;
  color: #2D4AD1;

}
span {
  text-decoration: underline;
  z-index: 2;
  position: relative;
  font-weight: 600;
}
a:hover, a.is-active {
  color: #2D4AD1;
  text-decoration: underline;
}
@media all and (min-width: 1200px) {
  nav a {
    margin-right: 27px;
    position: static;
  }
 

}
@media all and (min-width: 768px) {
  .navbar-links {
    position: static;
    transform: translateX(0);
    display: block;
  }
  nav a {
    font-size: 16px;
    position: static;
  }
  .mobile {
    display: none;
  }
}
</style>