<template>
    <header>
        <div class="column-one">
            <h1>Formamos líderes desde el primer día</h1>
            <div class="ctas">
                <a href="#" class="blue-button">CTA</a>
                <a href="#" class="link">Conocé nuestra propuesta</a>
            </div>
        </div>
        <div class="column-two">
            <img src="@/assets/img/header-learn-with-nnido.svg"/>
        </div>
    </header>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.blue-button {
    background: #2D4AD1;
    color: white;
    padding: 8px 32px;
    border-radius: 3px;
    margin-right: 8px;
}
.link {
    color: #2D4AD1;
    font-weight: 600;
}
@media all and (min-width: 768px) {
header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-left: 80px;
}
h1 {
    margin-bottom: 16px;
}
.column-two img {
    width: 100%;
}
}
@media all and (min-width: 1200px) {
    h1 {
        font-size: 70px;
    }
}

</style>