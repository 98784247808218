<template>
    <section class="cases" id="cases">
        <div class="title">
            <h2>Casos de estudio</h2>
            <p>Historias del equipo cocreando valor con nuestros clientes.</p>
        </div>    
        <div class="grid">
            <article>
                <img class="article-image" src="@/assets/img/cases_1.png"/>
                <h3>Cerveza Patagonia: Un pequeño experimento de titular</h3>
                <p>Acá iría una bajada para complementar el título una vez escrito el caso de estudio.</p>
                <a href="#"><span>Leer en Medium</span><img src="@/assets/img/arrow_blue_diagonal.png"/></a>
            </article>
            <article>
                <img class="article-image" src="@/assets/img/cases_2.png"/>
                <h3>Club Globals: Una bienvenida única a la ciudad de Berlín</h3>
                <p>Acá iría una bajada para complementar el título una vez escrito el caso de estudio.</p>
                <a href="#"><span>Leer en Medium</span><img src="@/assets/img/arrow_blue_diagonal.png"/></a>
            </article>
            <article>
                <img class="article-image" src="@/assets/img/cases_3.png"/>
                <h3>Learnflix: Crucemos los dedos que nos dejen contar este caso de estudio</h3>
                <p>Acá iría una bajada para complementar el título una vez escrito el caso de estudio.</p>
                <a href="#"><span>Leer en Medium</span><img src="@/assets/img/arrow_blue_diagonal.png"/></a>
            </article>
        </div>
    </section>
</template>
<style scoped>
h2 {
    margin-bottom: 16px;
}
.title {
    margin-bottom: 40px;
}
section {
    padding: 40px;
}
article {
    margin-bottom: 40px;
}
.article-image {
    width: 100%;
}
article h3, article p {
    margin-bottom: 24px;
}
a {
    font-weight: 700;
    display: inline-block;
    position: relative;
}
a:after {
    content: '';
    width: 100%;
    background: #2D4AD1;
    height: 2px;
    position: absolute;
    bottom: 0px;
    left: 0px;
}
a span {
    color: #2D4AD1;
}
a img {
    position: relative;
    top: 4px;
    width: 20px;
}
@media all and (min-width: 768px) {
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 16px;
    }
    article {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
    }
}
@media all and (min-width: 768px) {
    .grid {
        grid-gap: 40px;
    }
    section {
        padding: 0 120px;
    }
    h2 {
        font-size: 42px;
    }
    .title p {
        font-size: 18px;
    }
    .article-image {
        margin-bottom: 24px;
    }
}
</style>
