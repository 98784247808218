<template>
    <section class="mail wow slideInUp">
        <h2>¿No encontraste una oportunidad para vos?</h2>
        <p>Dejanos tu dirección de correo electrónico y recibí novedades sobre nuevas búsquedas para formar parte de nuestro equipo y novedades de la empresa.</p>
        <form>
            <div class="input-with-button">
                <input type="text" v-model="email" placeholder="tumail@ejemplo.com"/>
                <button type="submit" @click="validateForm"><img src="@/assets/img/arrow-right-black.svg"/></button>
            </div>
            <span v-if="formError" class="message error">Debés ingresar un mail válido</span>
            <span v-if="subscriptionSuccess" class="message">¡Gracias por suscribirte!</span>
        </form>
    </section>
</template>
<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'
export default {
  data: function () {
        return {
            email: '',
            subscriptionSuccess: false,
            formError: false
        }
    },
    methods: {
        handleFormSubmit () {
            const formData = {email: this.email, date: new Date()}
            Firebase.firestore().collection('newSubscribers')
            .add(formData)
            .then(() => {
            this.subscriptionSuccess = true;
            setTimeout(() => {this.subscriptionSuccess = false}, 5000)
            this.email = ''
          return
        })
        .catch(() => {
          alert('There was an error connecting to our server. Please try again later')
        })
    },
    validateForm (e) {
        e.preventDefault()
        if (this.email) {
            this.formError = false;
            this.handleFormSubmit()
            console.log('if') 
        } else {
            this.formError = true;
            console.log('else')
        }
    }
   }
   
}
</script>
<style scoped>
section {
    min-height: 100vh;
    background-image: url('../../assets/img/culture-background-newsletter-mobile.png');
    background-size: cover;
    background-position: top center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0 40px;
}
h2 {
    font-size: 26px;
    margin-bottom: 32px;
}
p {
    font-size: 16px;
    margin-bottom: 42px;
    font-family: 'Lato';
    line-height: 24px;
}
input {
    border: none;
    background: #F6F4F3;
    border: 2px solid black;
    border-right: none;
    height: 49px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    font-size: 16px;
    padding-left: 16px;
}
button {
    height: 49px;
    border: 2px solid black;
    border-left: none;
    width: 47px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: none;
}
form {
    position: relative;
}
.message {
    position: absolute;
    bottom: -24px;
    text-align: center;
    left: 0;
    width: 100%;
}
.error {
    color: red;
}
::placeholder {
    font-size: 18px;
}
.input-with-button {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media all and (min-width: 768px) {
    section {
        background-image: url('../../assets/img/img_new_1.png'), url('../../assets/img/img_new_2.png');
        background-size: contain;
        background-position: 100% 0%, 0% 0px;
        background-repeat:no-repeat;
        background-color: #F6F4F3;
        min-height: 70vh;
    }
    h2 {
        font-size: 42px;
    }
    p {
        font-size: 18px;
        max-width: 600px;
        margin: 0 auto 42px auto;
        line-height: 30px;
    }
    input {
        width: 354px;
    }
}
</style>