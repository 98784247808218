<template>
    <NavbarGrey/>
    <Header/>
    <StartupComponent/>
    <ServicesComponent/>
    <BootcampsComponent/>
    <BrandsComponent/>
    <DesignComponent/>
</template>
<script>
import NavbarGrey from '@/components/NavbarGrey'
import Header from '@/components/Home/Header'
import StartupComponent from '@/components/Home/StartupComponent'
import ServicesComponent from '@/components/Home/ServicesComponent'
import BootcampsComponent from '@/components/Home/BootcampsComponent'
import DesignComponent from "@/components/Home/DesignComponent"
import BrandsComponent from "@/components/Home/BrandsComponent"

export default {
    components: {NavbarGrey, Header, StartupComponent, ServicesComponent, BootcampsComponent, DesignComponent, BrandsComponent}
}
</script>