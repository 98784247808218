<template>
<header>
    <div class="wrapper">
        <div class="column-one wow slideInLeft">
            <h1>Creá productos y experiencias que tus clientes amen</h1>
            <a href="#transformación" class="desktop blue-button">Volemos en conjunto</a>    
        </div>
        <div class="column-two wow slideInRight" data-wow-delay=".2s">
            <img src="@/assets/img/img-header-home.png"/>
            <a href="#transformación" class="mobile blue-button">Volemos en conjunto</a>
        </div>
    </div>
</header>
</template>
<style scoped>
header {
    background: #F6F4F3;
    padding: 0 40px 74px 40px;
}
.column-two {
    display: flex;
    flex-direction: column;
    align-items: center;
}
img {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 40px;
}
.blue-button {
    background: #2D4AD1;
    color: white;
    border-radius: 3px;
    padding: 12px 42px;
    font-weight: 700;
}
.blue-button:hover {
  background: #292B2F;
  transition: .5s;
}
.desktop {
    display: none;
}
@media all and (min-width: 768px) {
    .mobile {
        display: none;
    }
    .desktop {
        display: inline-block;
    }
    .wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
    h1 {
        margin-bottom: 32px;
    }
}
@media all and (min-width: 1200px) {
    header {
        padding: 0 120px 130px 120px;
    }
    h1 {
        font-size: 70px;
        margin-bottom: 54px;
    }
    img {
        width: 90%;
    }
}
@media all and (min-width: 1600px) {
    header {
        padding: 120px 0px 130px 0px;
    }
    .wrapper {
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>