<template>
   <section id="transformación">
       <div class="column-one wow fadeIn" data-wow-delay=".2s">
           <h2>En NNIDO transformamos tu idea en el mejor producto digital</h2>
           <p>En la colaboración nace el futuro de la humanidad. Desde adquirir alas hasta llegar a la luna, no hay nada que nos detenga cuando jugamos en equipo.</p>
           <p>Cambiar el status quo sólo requiere de dos cosas: coraje para desafiarlo y herramientas para transformarlo.</p>
           <a href="#services">Conocé las herramientas<img src="@/assets/img/blue-arrow-down.svg"/></a>
       </div>
       <div class="wow slideInLeft column-two">
           <img src="@/assets/img/img1-home.png"/>
       </div>
   </section>
</template>
<style scoped>
    section {
        padding: 0px 40px;
    }
    .column-two img {
        width: 100%;
        margin-top: 48px;
    }
    p {
        margin-bottom: 32px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        font-family: 'Lato';
        font-weight: 400;
    }
    span {
        font-family: 'Lato'
    }
    span {
        display: block;
    }
    h2 {
        font-size: 22px;
        font-weight: 800;
        margin-bottom: 28px;
    }
    a {
        color: #2D4AD1;
        font-weight: 700;
        font-size: 18px;
    }
    a img {
        margin-left: 12px;
    }
    @media all and (min-width: 768px) {
        section {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .column-one {
            order: 1;
        }
        .column-two img {
            margin-top: 0;
        }
    }
    @media all and (min-width: 1200px) {
        section {
            padding: 0 120px;
            align-items: center;
            grid-gap: 60px;
        }
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 18px;
            line-height: 32px;
        }
    }
    @media all and (min-width: 1600px) {
        section {
            padding: 0;
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>