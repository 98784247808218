<template>
    <section>
        <h2>Nuestro proceso</h2>
        <div class="slider">
            <div class="step wow fadeIn">
                <img src="@/assets/img/paso_1.png"/>
                <h3>Aplicación</h3>
                <p>¡Date a conocer! Es importante que tu material refleje no solo tu experiencia sino también tu personalidad</p>
            </div>
            <div class="step wow fadeIn" data-wow-delay=".2s">
                <img src="@/assets/img/paso_2.png"/>
                <h3>Formulario técnico</h3>
                <p>Para saber más de vos te enviamos algunas preguntas para conocer mejor tu perfil técnico y tus habilidades</p>
            </div>
            <div class="step wow fadeIn" data-wow-delay=".4s">
                <img src="@/assets/img/paso_3.png"/>
                <h3>Entrevista telefónica</h3>
                <p>¡Nuestra primera conversación!. Buscamos conocer más en profundidad tus habilidades.</p>
            </div>
            <div class="step wow fadeIn" data-wow-delay=".6s">
                <img src="@/assets/img/paso_4.png"/>
                <h3>Instancia colaborativa</h3>
                <p>Queremos que el equipo te conozca y vos conozcas al equipo.</p>
            </div>
        </div>
    </section>
</template>

<style scoped>
section {     
    margin: 0 40px 100px 40px;
}
h2 {
    font-size: 26px;
    margin-bottom: 32px;
}
.step img {
    width: 100%;
}
.slider {
  display: flex;
  overflow-x: scroll;

}
.step {
    flex-shrink: 0;
    flex-basis: calc(100% - 2rem);
    margin-right: 16px;
    position: relative;
}
h3 {
    margin-top: 32px;
    margin-bottom: 20px;
    font-size: 20px;
}
p {
    font-size: 18px;
    line-height: 30px;
    font-family: 'Lato';
}
@media all and (min-width: 768px) {
    .slider {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 16px;
        overflow-x: hidden;
    }
}
@media all and (min-width: 1200px) {
    section {
        margin: 0px 120px 100px 120px;
    }
}
@media all and (min-width: 1600px) {
    section {
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
