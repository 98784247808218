<template>
    <header>
        <div class="wrapper">
            <div class="column-one wow slideInLeft">
                <h1>Las personas ignoran el diseño que ignora a las personas</h1>
                <img class="mobile header-image" src="@/assets/img/img_header.png"/>
                <p>Aprendé a detectar rápido qué es lo que tus clientes aman.</p>
                <div class="ctas">
                    <a class="blue-button" href="#innovación">¿Querés saber más?</a>
                </div>
            </div>
            <div class="column-two desktop wow slideInLeft">
                <img class="header-image" src="@/assets/img/img_header.png"/>     
            </div>
        </div>
    </header>
</template>
<style scoped>
    header {
        background: #F6F4F3;
        padding: 25px 40px 55px 40px;
    }
    img.header-image {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 10px;
    }
    p {
        font-size: 14px;
        line-height: 22px;
        font-family: 'Lato';
    }
    .ctas {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;
    }
    a {
        color: #2D4AD1;
        font-weight: 700;
        font-size: 18px;
        white-space: nowrap;
    }
    .blue-button {
        font-weight: 700;
        background: #2D4AD1;
        color: white;
        padding: 13px 25px;
        margin-bottom: 24px;
        border-radius: 3px;
    }
    .blue-button:hover {
        background: #292B2F;
        transition: .5s;
    }
    a img {
        margin-left: 8px;
    }
    .desktop {
        display: none;
    }
    @media all and (min-width: 768px) {
        .mobile {
            display: none;
        }
        .desktop {
            display: block;
        }
        .wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        img.header-image {
            margin-top: 0;
            margin-bottom: 0;
        }
        p {
            margin-top: 32px;
        }
    }
    @media all and (min-width: 1200px) {
        .wrapper {
           
            align-items: center;
            min-height: 70vh;
            padding: 0 80px;
        }
        h1 {
            font-size: 42px;
        }
        p {
            font-size: 18px;
            line-height: 30px;
        }
        .ctas {
            margin-top: 48px;
            flex-direction: row;
            align-items: center;
        }
        .blue-button {
            margin-bottom: 0;
            margin-right: 16px;
        }
    }
    @media all and (min-width: 1600px) {
        .wrapper {
             max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
            padding: 0;
        }
    }
</style>