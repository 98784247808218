<template>
    <section class="testimonies" id="testimonies">
        <article class="wow fadeIn">
            <img class="photo" src="@/assets/img/image-testimonies-orne.png"/>
            <p>“La calidez y el reconocimiento de la individualidad siento que  empoderan mi colaboración y crecimiento.”</p>
            <img class="quote" src="@/assets/img/quote.svg"/>
            <strong>Orne Facciola</strong>
            <span>Business Operations Strategist</span>
        </article>
        <article class="wow fadeIn" data-wow-delay=".2s">
            <img class="photo" src="@/assets/img/image-testimonies-meli.png"/>
            <p>“Mi parte favorita de los días en NNIDO son los puntos de encuentro con el equipo con la energía bien arriba.”</p>
            <img class="quote" src="@/assets/img/quote.svg"/>
            <strong>Meli Gatto</strong>
            <span>Media Production & Operations Associate</span>
        </article>
        <article class="wow fadeIn" data-wow-delay=".4s">
            <img class="photo" src="@/assets/img/image-testimonies-dani.png"/>
            <p>“Me motiva nuestra confianza en el proceso y el compromiso del equipo con la excelencia y la calidad.”</p>
            <img class="quote" src="@/assets/img/quote.svg"/>
            <strong>Dan Morrongiello</strong>
            <span>UX Strategist</span>
        </article>
    </section>
</template>
<style scoped>
.testimonies {
  display: flex;
  overflow-x: scroll;
  margin: 0 40px 100px 40px;

}
article {
    flex-shrink: 0;
    flex-basis: calc(100% - 2rem);
    margin-right: 16px;
    position: relative;
}
.photo {
    border-radius: 10px;
    width: 100%;
}
p {
    font-family: 'Overpass Mono', monospace;
    margin-top: 26px;
}
.quote {
    margin: 25px 0px
}
strong, span {
    display: block;
    font-family: "Lato";
}
@media all and (min-width: 768px) {
    .testimonies {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 26px;
    }
    article {
        margin-right: 0px;
    }
}
@media all and (min-width: 1200px) {
    .testimonies {
        margin: 0px 120px 50px 120px;
        overflow-x: hidden;
    }
}
@media all and (min-width: 1200px) {
    .testimonies {
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>