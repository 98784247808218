<template>
    <header>
        <div class="column-one wow slideInLeft">
            <h1>Sumate al equipo</h1>
            <div class="ctas desktop">
                <a href="#open-positions" class="blue-button">Postulate</a>
                <a href="#culture">Conocé nuestra cultura<img class="arrow" src="@/assets/img/blue-arrow-down.svg"/></a>
            </div>
        </div>
        <div class="column-two wow slideInLeft" data-wow-delay=".2s">
            <img src="@/assets/img/culture-image-header.png" class="header-image"/>
            <div class="ctas mobile">
                <a href="#open-positions" class="blue-button">Postulate</a>
                <a href="#culture">Conocé nuestra cultura <img class="arrow" src="@/assets/img/blue-arrow-down.svg"/></a>
            </div>
        </div>
    </header>
</template>
<style scoped>
header {
    margin: 0px 40px 100px 40px;
}
h1 {
    font-size: 40px;
    margin-bottom: 32px;
}
.desktop {
    display: none;
}
.header-image {
    width: 100%;
    margin-bottom: 24px;
}
.blue-button {
    background: #2D4AD1;
    color: white;
    padding: 8px 32px;
    border-radius: 3px;
    margin-right: 16px;
}
.blue-button:hover {
  background: #292B2F;
  transition: .5s;
}
.mobile a:not(.blue-button) {
    display: block;
    margin-top: 20px;
    
}

a {
    font-weight: 700;
}
a:not(.blue-button) {
    color: #2D4AD1;
    font-weight: 700;
}
.arrow {
    width: 12px;
    margin-left: 8px;
}
@media all and (min-width: 768px) {
    header {
        display: grid;
        grid-template-columns: 60% 40%;
        justify-content: space-between;
        align-items: center;
    }
    .column-one {
        margin-right: 16px;
    }
    .mobile {
        display: none;
    }
    .desktop {
        display: block;
    }
    .ctas {
        margin-top: 20px;
    }
}
@media all and (min-width: 980px) {
    header {
        grid-template-columns: 50% 50%;
        grid-gap: 40px;
    }
    .header-image {
        max-width: 85%;
    }
}
@media all and (min-width: 1200px) {
    header {
        margin: 0px 120px 100px 120px;
        grid-gap: 120px;
    }
    h1 {
        font-size: 70px;
    }
    .ctas {
        margin-top: 40px;
    }
}
@media all and (min-width: 1600px) {
    header {
        max-width: 1300px;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 120px;
    }
}
</style>