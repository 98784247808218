<template>
<section>
  <div class="column-one wow fadeIn" data-wow-delay=".2s">
      <h2>¿Cómo lo hacen?</h2>
      <p>Es común creer que necesitamos desarrollar un MVP (producto mínimo viable) para poder obtener feedback rápido de nuestros usuarios, sin embargo esto no es cierto. Gracias al Design Sprint podés construir un prototipo digital y obtener en tan sólo una semana esta información valiosa para el lanzamiento de tu producto.</p>
  </div>  
  <div class="column-two wow fadeIn">
    <img src="@/assets/img/img_2.png"/>
  </div>
</section>
</template>

<style scoped>
    section {
        padding: 0 40px;
    }
    img {
        width: 100%;
        margin-top: 40px;
    }
    h2 {
        margin-top: 32px;
        font-size: 26px;
        line-height: 36px;
    }
    p {
        margin-top: 24px;
        font-size: 16px;
        line-height: 26px;
        font-family: 'Lato';
    }
    @media all and (min-width: 768px) {
        section {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
        }
        h2, img {
            margin-top: 0;
        }
    }
    @media all and (min-width: 1200px) {
        section {
            grid-gap: 36px;
            padding: 0 120px;
            align-items: center;
        }
        h2 {
            font-size: 42px;
            line-height: 52px;
        }
        p {
            font-size: 18px;
            line-height: 30px;
            margin-top: 24px;
        }
    }
    @media all and (min-width: 1600px) {
        section {
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
            padding: 0;
        }
    }
</style>