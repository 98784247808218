<template>
<section class="opportunities" id="open-positions">
    <h2>Oportunidades abiertas</h2>
    <div class="grid">
        <div class="position">
            <div>
                <h3>Diseño visual</h3>
                <span class="remote">Remoto</span>
                <span class="full">Full-Time</span>
            </div>
            <a href="https://nnido.recruitee.com/o/diseno-visual" target="_blank" class="blue-button">Ver posición</a>
        </div>
        <!--<div class="position">
            <div>
                <h3>Design Transformation Strategist</h3>
                <span class="remote">Remoto</span>
                <span class="part">Part-Time</span>
            </div>
            <a href="#" class="blue-button">Ver posición</a>
        </div>
        <div class="position">
            <div>
                <h3>Business Developer</h3>
                <span class="remote">Remoto</span>                 
                <span class="full">Full-Time</span>
            </div>
            <a href="#" class="blue-button">Ver posición</a>
        </div>-->
    </div>
</section>
</template>

<style scoped>
section {
    margin: 0px 40px 100px 40px;
}
h2 {
    font-size: 26px;
    max-width: 80%;
    margin-bottom: 32px;
    font-weight: 700;
}
h3 {
    color: #2D4AD1;
    font-weight: 700;
    margin-bottom: 16px;
}
span {
    margin-right: 8px;
    padding: 4px 8px;
    font-weight: 700;
    background: #F0F0F0;;
    font-size: 12px;
    border-radius: 2px;
}
.position {
    margin-bottom: 40px;
    padding-bottom: 40px;
}
.position div {
    margin-bottom: 24px;
}
.full {
    background: #FFECEC;
}
.part {
    background: #ECF5FF;
}
.blue-button {
    background: #2D4AD1;
    padding: 5px 20px;
    color: white;
    border-radius: 3px;
    font-weight: 600;
}
.position {
    border-bottom: 1px solid #E0E0E0
}
@media all and (min-width: 768px) {

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 50px;
    }
    .position {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border: none;
        padding-bottom: 0;
    }
    .position:first-child {
        border-bottom: 1px solid #E0E0E0
    } 
    .position div {
        margin-bottom: 40px;
    }
}
@media all and (min-width: 1200px) {
    section {
        margin: 0px 120px 100px 120px;
    }
}
@media all and (min-width: 1600px) {
    section {
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>