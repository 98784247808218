/* eslint-disable no-irregular-whitespace */
<template>
<section>
    <div class="wrapper">
        <div class="column-one wow fadeIn">
            <img src="@/assets/img/jake.png"/>
        </div>
        <div class="column-two wow fadeIn" data-wow-delay=".2s">
            <p class="quote">“Para mi, los Design Sprints son la mejor manera de trabajar: un problema desafiante, tiempo de concentración, un equipo de gente colaborando en conjunto y en su mejor versión, discutiendo de manera constructiva para lograr progreso. En tu vida, sólo habrá una cantidad limitada de momentos así, hay que saborearlo.”</p>
            <p class="author">Jake Knapp</p>
            <p class="title">Co-creador del Design Sprint y co-autor del libro “Sprint”.</p>
        </div>
    </div>
</section>
</template>
<style scoped>
    section {
        padding: 40px;
        padding-bottom: 50px;
        background: #F6F4F3;
        margin: 0;
        
    }
    img {
        width: 100%;
    }
    h2 {
        margin-top: 32px;
        font-size: 26px;
        line-height: 36px;
    }
    p {
        margin-top: 24px;
        font-size: 14px;
        line-height: 21px;
    }
    .title {
        margin-top: 0px;
    }
    .quote {
        font-family: "Overpass Mono";
    }
    .author {
        font-weight: 700;
        color: #fe8359;
    }
    .author, .title {
        font-family: 'Lato';
    }
    @media all and (min-width: 768px) {
        .wrapper {
            display: grid;
            grid-template-columns: 30% 60%;
            grid-gap: 16px;
        }
        h2 {
            margin-top: 0;
        }
    }
    @media all and (min-width: 1200px) {
        section {
            margin: 0;
            padding-top: 80px;
        }
        .wrapper {
            grid-gap: 36px;
            align-items: center;
            padding: 0 120px;
        }
        h2 {
            font-size: 42px;
            line-height: 52px;
        }
        p {
            font-size: 18px;
            line-height: 28px;
            margin-top: 24px;
        }
    }
    @media all and (min-width: 1600px) {
        .wrapper {
            margin: 0 auto;
            max-width: 1300px;
            padding: 0;
        }
    }
</style>