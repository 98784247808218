<template>
    <NewNavbar/>
    <Header/>
    <AboutYou :errors="errors" :subscriptionSuccess="subscriptionSuccess" @change="handleInputChange"/>
    <LookingFor :errors="errors" :subscriptionSuccess="subscriptionSuccess" @change="handleInputChange"/>
    <div class="button-div">
        <button class="blue-button" @click="handleFormSubmit">Enviar</button>
        <span v-if="subscriptionSuccess">¡Gracias! Te responderemos a la brevedad</span>
        <span v-if="formError">Debés revisar el formulario. Hay campos sin completar o inválidos</span>
    </div>
</template>
<script>
import NewNavbar from '@/components/NewNavbar'
import Header from '@/components/Contact/Header'
import AboutYou from '@/components/Contact/AboutYou'
import LookingFor from '@/components/Contact/LookingFor'
import Firebase from 'firebase/app'
import 'firebase/firestore'
export default {
    components: {
        NewNavbar,
        Header,
        AboutYou,
        LookingFor
    },
    mounted: function () {
        window.scrollTo(0,0)
    },
    data: function () {
        return {
            formData: {
                name: '',
                email: '',
                linkedin: '',
                intro: '',
                lookingFor: 'Contratar a su equipo para mi proyecto',
                for: 'Resolver un prototipo para testear una idea',
                wouldLikeTo: '',
                includesDevelopment: false,
                numberOfPeople: 0,
                where: ''
            },
            errors: {},
            subscriptionSuccess: false,
            formError: false
        }
    },
    methods: {
        handleInputChange (event) {
            this.formData[event.name] = event.value
        },
        handleFormSubmit () {
            let dataToSend = {
                name: this.formData.name,
                email: this.formData.email,
                linkedin: this.formData.linkedin,
                intro: this.formData.intro,
                lookingFor: this.formData.lookingFor,
                for: this.formData.for,
                wouldLikeTo: this.formData.wouldLikeTo
            }
            if (this.formData.lookingFor === 'Contratar a su equipo para mi proyecto') {
                dataToSend.includesDevelopment = this.formData.includesDevelopment
            }
            else if (this.formData.lookingFor === 'Entrenar al equipo en Design Sprints') {
                dataToSend.numberOfPeople = this.formData.numberOfPeople
            }
            else {
                dataToSend.where = this.formData.where
            }
            if (this.validateForm(dataToSend)) {
                Firebase.firestore().collection('newContactForm')
                .add(dataToSend)
                .then(() => {
                    console.log(dataToSend)
                    this.subscriptionSuccess = true;
                    setTimeout(() => {this.subscriptionSuccess = false}, 5000)
                return
                })
                .catch((error) => {
                    console.log(error)
                    alert('There was an error connecting to our server. Please try again later')
                })
            } 
         
        },

        validateForm (data) {
            let valid = true
            this.formError = false
            const urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
            const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if (!data.name) {
                valid = false;
                this.errors.name = true
            } else this.errors.name = false
            if (!data.email || !emailRegex.test(data.email)) {
                valid = false
                this.errors.email = true
            } else this.errors.email = false
            if (data.linkedin && !urlRegex.test(data.linkedin)) {
                valid = false
                this.errors.linkedin = true
            } else this.errors.linkedin = false
            if (!data.intro) {
                valid = false
                this.errors.intro = true
            } else this.errors.intro = false
            if (!data.wouldLikeTo) {
                valid = false
                this.errors.wouldLikeTo = true
            } else this.errors.wouldLikeTo = false
            if (data.lookingFor === 'Entrenar al equipo en Design Sprints') {
                if (!data.numberOfPeople) {
                    valid = false
                    this.errors.numberOfPeople = true;
                } else this.errors.numberOfPeople = false
            } else if (data.lookingFor === 'Crear contenido en conjunto para la comunidad') {
                if (!data.where) {
                    valid = false
                    this.errors.where = true
                } else this.errors.where = false
            }
            if (!valid) {
                this.formError = true
            } else this.formError = false
            return valid
        }
    }
}
</script>
<style scoped>
.button-div {
    margin-bottom: 120px;
}
.blue-button {
    background: #2D4AD1;
    border-radius: 5px;
    color: white;
    border: none;
    padding: 18px 50px;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    margin-left: 40px;
    cursor: pointer;
}
.blue-button:hover {
  background: #292B2F;
  transition: .5s;
}
span {
    display: block;
    font-weight: bold;
    margin-left: 40px;
    margin-top: 16px;
}
@media all and (min-width: 768px){
    .blue-button, span {
        margin-left: 120px;
    }
}
@media all and (min-width: 1600px){
    .button-div {
        max-width: 1300px;
        margin-right: auto;
        margin-left: auto;
    }
}
</style>