import { createWebHistory, createRouter } from "vue-router";
//import NnidoUniversity from "@/views/NnidoUniversity.vue";
import BuyProduct from "@/views/BuyProduct.vue";
import Content from "@/views/Content.vue";
import Bootcamp from "@/views/Bootcamp.vue";
//import Payment from "@/views/Payment.vue";
import LearnWithNnido from "@/views/LearnWithNnido.vue";
import Culture from "@/views/Culture.vue";
import Experience from "@/views/Experience.vue"
import Home from "@/views/Home.vue"
import DesignSprint from "@/views/DesignSprint.vue"
import Contact from "@/views/Contact.vue"

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/buy-product",
    name: "BuyProduct",
    component: BuyProduct,
  },
  {
    path: "/content",
    name: "Content",
    component: Content,
  },  
  {
    path: "/bootcamp",
    name: "Bootcamp",
    component: Bootcamp,
  },
  {
    path: "/design-sprint",
    name: "Design Sprint",
    component: DesignSprint,
  },
  {
    path: '/learn',
    name: 'LearnWithNnido',
    component: LearnWithNnido
  },
  {
    path: '/cultura',
    name: 'Culture',
    component: Culture
  },
  {
    path: '/experience',
    name: 'Experience',
    component: Experience
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: Contact
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
