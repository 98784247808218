<template>
<section class="wow slideInUp">
        <h2>Nuestro compromiso de transparencia y calidad con nuestros clientes</h2>
        <p>Como equipo, nos comprometemos a tomar proyectos que reúnan las condiciones necesarias para que generar los mejores resultados.</p>
        <p>Desde el primer día, NNIDO se ha enfocado en producir valor de manera ágil, centrada en las personas y con impacto en el negocio. Trabajamos para crear las mejores soluciones sin desperdiciar un sólo centavo o un minuto de tu tiempo.</p>
</section>
</template>
<script>
export default {
    data: function () {
        return {
            readMore: false
        }
    }
}
</script>
<style scoped>
section {
    padding: 0 40px;
}
button {
    background: none;
    border: none;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #2D4AD1
}
h2 {
    font-size: 20px;
    margin-bottom: 24px;
}
p {
    font-size: 18px;
    margin-bottom: 16px;
    font-family: 'Lato';
}
.desktop {
    display: none;
}

@media all and (min-width: 768px) {
    .desktop {
        display: block;
    }
    .mobile {
        display: none;
    }
}
@media all and (min-width: 1200px) {
    section {
        padding: 0 120px;
    }
    p {
        line-height: 30px;
    }
}
@media all and (min-width: 1600px) {
    section {
        padding: 0px;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>