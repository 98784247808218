<template>
  <div id="app">
    <!--<Navbar/>-->
    <router-view />

    <Footer/>
  </div>
</template>

<script>
//import Navbar from "@/components/Navbar"
// import Experience from '@/views/Experience'
// import Culture from '@/views/Culture'

import Footer from "@/components/Footer"
export default {
  name: 'App',
  components: {
    Footer
  },
  data: function () {
    return {
      PREFIX_PATH: process.env.PREFIX_PATH
    }
  }
}
</script>

<style>
* {
  margin: 0; 
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
  scroll-behavior: smooth;
  color: #2D395A;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
a {
  text-decoration: none;
}

section {
  margin: 120px 0px;
}

@media all and (max-width: 768px) {
  section {
    margin: 70px 0px;
  }
  * {
    animation-name: none !important;
  }
}
</style>
