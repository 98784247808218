<template>
    <section class="growing">
        <div class="title">
            <h2>Creciendo en comunidad</h2>
            <p>Formamos parte de una comunidad de líderes y facilitadores referentes en UX. </p>
        </div>
        <div class="grid">
            <div>
                <a href="https://www.instagram.com/wearennido/?hl=es-la" target="_blank">
                    <img src="@/assets/img/img-instagram.png"/>
                    <p>Seguinos en Instagram</p>
                </a>
            </div>
            <div>
                <a href="https://ar.linkedin.com/company/nnido" target="_blank">
                    <img src="@/assets/img/img-linkedin.png"/>
                    <p>Conectemos en LinkedIn</p>
                </a>
            </div>
            <div>
                <a href="https://medium.com/nnido" target="_blank">
                    <img src="@/assets/img/img-medium.png"/>
                    <p>Leenos en Medium</p>
                </a>
            </div>
        </div>
    </section>
</template>
<style scoped>
.growing {
    padding: 0 40px;
}
.title {
    text-align: center;
    margin-bottom: 50px;
}
.title h2 {
    color: #2D4AD1;
    margin-bottom: 16px;
}
img {
    width: 100%;
}
.grid div {
    margin-bottom: 40px;
}
.grid p {
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin-top: 16px;
}
@media all and (min-width: 768px) {
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 16px;
    }
}
@media all and (min-width: 1200px) {
    .growing {
        padding: 0 120px;
    }
    .grid {
        grid-gap: 32px;
    }
    .title h2 {
        font-size: 42px;
    }
    .title p {
        font-size: 18px;
    }
}
</style>