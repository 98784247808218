<template>
<section class="wow slideInUp">
    <div class="wrapper">
        <div class="column-one">
            <h2>¿Qué es el Design Sprint y cómo funciona?</h2>
            <p>Es un método creado por Google Ventures que ayuda a resolver grandes desafíos y obtener respuestas críticas del negocio en tan sólo una semana.</p>
            <p>Gracias a este proceso las empresas pueden crear nuevos productos o mejorar soluciones ya existentes.</p>
        </div>
        <div class="column-two">
            <img src="@/assets/img/img-ds_animated.svg"/>
        </div>
    </div>
</section>
</template>
<style scoped>
    section {
        padding: 60px 40px 45px 40px;
        background: url('../../assets/img/bkg-black-mobile.png');
        background-size: cover;
    }
    img {
        width: 100%;
        margin-top: 60px;
    }
    h2 {
        margin-top: 32px;
        font-size: 26px;
        line-height: 36px;
        color: white;
    }
    p {
        margin-top: 24px;
        font-size: 14px;
        line-height: 21px;
        font-family: "Overpass Mono";
        color: #FCD065
    }
    @media all and (min-width: 768px) {
        .wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
        }
        h2 {
            margin-top: 0;
        }
        img {
            margin-top: 0px;
        }
    }
    @media all and (min-width: 1200px) {
        section {
            background-image: url('../../assets/img/bkg-black.png');  
            padding: 40px 0px;
           }
        .wrapper {
            grid-gap: 36px;
            align-items: center;
            padding: 0 120px;
        }
        h2 {
            font-size: 42px;
            line-height: 52px;
        }
        p {
            font-size: 18px;
            line-height: 30px;
            margin-top: 24px;
        }
        img {
            width: 80%;
        }
     }
     @media all and (min-width: 1600px) {
         .wrapper {
             padding: 0;
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
         }
     }
</style>
