<template>
    <section class="our-learning">
        <div class="title">
            <h2>Nuestro aprendizaje</h2> 
            <p>Compartimos lo que fuimos aprendiendo a lo largo de nuestro recorrido como equipo.</p>
        </div> 
            <div class="main-article">
                <div>
                    <small class="mobile">Producto</small>
                    <img class="thumbnail" src="@/assets/img/article_image.png"/>
                </div>
                <div>
                    <div class="article-header">
                        <small class="desktop">Producto</small>
                        <p class="time"><img src="@/assets/img/tiempo-negro.svg"/>10 min de lectura</p>
                    </div>
                    <h3>Un buen pase de diseño a desarrollo</h3>
                    <p>Acá iría un pequeño extracto de esta lectura o artículo sobre cómo llevar adelante un buen pase entre diseño y desarrollo. Puede tener un máximo de 3 líneas.</p> 
                    <a href="https://medium.com/nnido/que-la-falta-de-tiempo-para-tus-proyectos-no-te-desespere-8c4eaba456ad" target="_blank"><span>Leer en Medium</span><img src="@/assets/img/arrow_blue_diagonal.png"/></a>
                    <div class="author">
                        <img src="@/assets/img/img-Lu.png"/>
                        <div>
                            <p>Lu Wainfeld</p>
                            <p>Front-End developer</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="article">
                    <small class="green">Design Sprint</small>
                    <img class="thumbnail" src="@/assets/img/Thumbnail-4.png"/>
                    <p class="time"><img src="@/assets/img/tiempo-gris.svg"/>5 min de lectura</p>
                    <h3>Que la falta de tiempo para tus proyectos no te desespere.</h3>
                    <p>¿Por qué está todo el mundo hablando de “Design Sprint”?</p>
                    <a href="https://medium.com/nnido/que-la-falta-de-tiempo-para-tus-proyectos-no-te-desespere-8c4eaba456ad" target="_blank"><span>Leer en Medium</span><img src="@/assets/img/arrow_blue_diagonal.png"/></a>
                </div>
                <div class="article">
                    <small class="blue">Cultura ágil</small>
                    <img class="thumbnail" src="@/assets/img/Thumbnail-5.png"/>
                    <p class="time"><img src="@/assets/img/tiempo-gris.svg"/>10 min de lectura</p>
                    <h3>El todo es más que la suma de las partes: la importancia de colaborar con una visión</h3>
                    <p>Cómo un cambio de perspectiva nos enfoca a trabajar mejor.</p>
                    <a href="https://medium.com/nnido/el-todo-es-m%C3%A1s-que-la-suma-de-las-partes-la-importancia-de-colaborar-con-una-visi%C3%B3n-4d8265842bbd" target="_blank"><span>Leer en Medium</span><img src="@/assets/img/arrow_blue_diagonal.png"/></a>
                </div>     
                <div class="article">
                    <small class="blue">Cultura ágil</small>
                    <img class="thumbnail" src="@/assets/img/Thumbnail-6.png"/>
                    <p class="time"><img src="@/assets/img/tiempo-gris.svg"/>6 min de lectura</p>
                    <h3>El principal factor de éxito en una cultura de innovación: El feedback</h3>
                    <p>Más fácil dicho que hecho, el feedback es una de las herramientas fundamentales de líderes de equipos y compañías.</p>
                    <a href="https://medium.com/nnido/el-principal-factor-de-%C3%A9xito-en-una-cultura-de-innovaci%C3%B3n-el-feedback-14686268bfb6" target="_blank"><span>Leer en Medium</span><img src="@/assets/img/arrow_blue_diagonal.png"/></a>
                </div>               
            </div>  
    </section>

</template>
<style scoped>
    .our-learning {
        padding: 0px 40px;
    }
    .title p {
        margin-top: 16px;
        margin-bottom: 48px;
    }
    .thumbnail {
        width: 100%;
        margin-top: 16px;
    }
    a {
        position: relative;
        font-weight: 700;
        display: inline-block;
    }
    a:after {
        content: '';
        width: 100%;
        background: #2D4AD1;
        height: 2px;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }
    a span {
        color: #2D4AD1;
    }
    a img {
        position: relative;
        top: 4px;
        width: 20px;
        
    } 
    small {
        background: #F0F0F0;
        padding: 4px 16px;
        position: relative;
        font-weight: 600;
    }
    .time {
        color: #828282;
        font-weight: 700;
        margin: 16px 0px;
    }
    .time img {
        margin-right: 8px;
    }
    .main-article h3, .article h3 {
        margin-bottom: 16px;
    }
    .main-article p, .article p {
        margin-bottom: 24px;
    }
    .main-article p {
        color: black;
    }
    .author {
        display: flex;
        align-items: center;
    }
    .author p {
        margin-bottom: 0px;
     }
    .author p:first-child {
        font-weight: bold;
        margin-bottom: 4px;
    }
    .author img {
        width: 50px;
        margin-right: 16px;
    }
    .author {
        margin: 35px 0px 62px 0px;
    }
    small:before {
        content: '';
        width: 5px;
        height: 100%;
        background: #FEA2D3;    
        position: absolute;
        left: 0;
        top: 0;
    }
    .green:before {
        background: #37A884;
    }
    .blue:before {
        background: #4534FF;
    }
    .article {
        margin-bottom: 72px;
    }
    .desktop {
        display: none;
    }
    @media all and (min-width: 768px) {
        .main-article {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
            align-items: center;
        }
        .mobile {
            display: none;
        }
        .desktop {
            display: inline-block;
        }
        .article-header {
            display: flex;
            align-items: center;
        }
        .article-header img {
            margin-left: 24px;
        }
        .grid {
            display: flex;
            align-items: stretch;
        }
        .article:not(:last-child) {
            margin-right: 16px;
        }
    }
    @media all and (min-width: 1200px) {
        .our-learning {
            padding: 0 120px;
        }
        .title h2 {
            font-size: 42px;
        }
        .title p {
            font-size: 18px;
            width: 35%;
        }
        .main-article {
            grid-gap: 48px;
            margin-bottom: 80px;
        }
        .main-article h3 {
            font-size: 26px;
        }
        .main-article p {
            font-size: 18px;
            line-height: 30px;
        }
        .author p {
            font-size: 16px;
        }
        a {
            font-size: 18px;
        }
        .article:not(:last-child) {
            margin-right: 48px;
        }
        .article {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
</style>
