<template>
<section id="innovación">
    <div class="column-one wow fadeIn">
        <img src="@/assets/img/img_ds_1.png"/>
    </div>
    <div class="column-two wow fadeIn" data-wow-delay=".2s">
        <h2>La innovación no sucede, es diseñada para que suceda</h2>
        <p>Existe un método que ayuda a las mejores empresas tecnológicas a ser las primeras en lanzar productos que sus clientes y usuarios aman.</p>
        <div class="logos">
            <img src="@/assets/img/logo-google.png" alt="Google" class="wow fadeIn" data-wow-delay=".4s"/>
            <img src="@/assets/img/logo-twitter.png" alt="Twitter" class="wow fadeIn" data-wow-delay=".6s"/>
            <img src="@/assets/img/logo-slack.png" alt="Slack" class="wow fadeIn" data-wow-delay=".8s"/>
            <img src="@/assets/img/logo-airbnb.png" alt="Airbnb" class="wow fadeIn" data-wow-delay="1s"/>

        </div>
    </div>
</section>
</template>

<style scoped>
    section {
        padding: 0 40px;
    }
    img {
        width: 100%;
    }
    h2 {
        margin-top: 32px;
        font-size: 26px;
        line-height: 36px;
    }
    p {
        margin-top: 24px;
        font-size: 16px;
        line-height: 26px;
        font-family: 'Lato';
    }
    .logos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 28px;
    }
    @media all and (min-width: 768px) {
        section {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
        }
        h2 {
            margin-top: 0;
        }
    }
    @media all and (min-width: 1200px) {
        section {
            grid-gap: 36px;
            align-items: center;
            padding: 0 120px;
        }
        h2 {
            font-size: 42px;
            line-height: 52px;
        }
        p {
            font-size: 18px;
            line-height: 30px;
            margin-top: 24px;
        }
        .logos {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 8px;
        }
    }
    @media all and (min-width: 1600px) {
        section {
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
            padding: 0;
        }
    }
</style>