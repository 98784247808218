<template>
    <header>
        <div class="column-one wow slideInLeft">
            <h1>¡Hablemos!</h1>
            <p>Ya sea que quieras a nuestro equipo en tu proyecto o que colaboremos con tu contenido en redes, ¡este es el lugar!.</p>
            <div class="desktop">
                <p class="question">¿Buscás sumarte a nuestro equipo?</p>
                <a :href="`${baseUrl}/cultura`"><span>Conocé las oportunidades</span><img class="arrow" src="@/assets/img/arrow_blue_diagonal.png"/></a>
                <p class="question">¿Simplemente querés saludar?</p>
                <a href="https://www.instagram.com/aprendeconnnido/" target="_blank"><span>Dejanos un mensajito en Instagram</span><img class="arrow" src="@/assets/img/arrow_blue_diagonal.png"/></a>
            </div>
        </div>
        <div class="column-two wow slideInLeft">
            <img class="hero-img" src="@/assets/img/hero-temp.png"/>
            <div class="mobile">
                <p class="question">¿Buscás sumarte a nuestro equipo?</p>
                <a :href="`${baseUrl}/cultura`"><span>Conocé las oportunidades</span><img class="arrow" src="@/assets/img/arrow_blue_diagonal.png"/></a>
                <p class="question">¿Simplemente querés saludar?</p>
                <a href="https://www.instagram.com/wearennido/?hl=es-la" target="_blank"><span>Dejanos un mensajito en Instagram</span><img class="arrow" src="@/assets/img/arrow_blue_diagonal.png"/></a>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    data: function () {
        return {
            baseUrl: 'http://nnido.com.ar'
        }
    }
}
</script>
<style scoped>
    header {
        padding: 0 40px;
        margin-bottom: 70px;
    }
    .hero-img {
        width: 100%;
    }
    h1 {
        font-weight: bold;
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 16px;
    }
    p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 40px;
        font-family: 'Lato';
    }
    .question {
        margin-top: 26px;
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 30px;
        font-family: 'Lato';
    }
    a {
        font-weight: bold;
        font-size: 18px;
        line-height: 40px; 
        text-decoration: underline;
        color: #2D4AD1;
    }
    a span {
        color: #2D4AD1;
    }
    .arrow {
        position: relative;
        top: 4px;
    }
    .desktop {
        display: none;
    }
    @media all and (min-width: 768px) {
        header {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
        }
        .mobile {
            display: none;
        }
        .desktop {
            display: block;
        }
        a {
            text-decoration: none;
            position: relative;
        }
        a:after {
            content: '';
            position: absolute;
            bottom: -4px;
            width: 100%;
            height: 2px;
            background: #2D4AD1;
            left: 0;
        }
    }
    @media all and (min-width: 1200px) {
        header {
            padding: 0 120px;
            margin-bottom: 120px;
            margin-top: 40px;
        }
        h1 {
            font-size: 70px;
            line-height: 88px;
        }
        p {
            font-size: 18px;
            line-height: 34px;
        }
        a span {
            font-size: 18px;
            line-height: 25px;
        }
    }
    @media all and (min-width: 1600px) {
        header {
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>